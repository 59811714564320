import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight, faChevronLeft, faHome, faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"

const Page = () => (
    <Layout container={false}>
        <SEO title="Entschuldigung, wir arbeiten an dieser Seite!" />

        <div className="text-center" style={{fontSize:"1.5em",  padding: "80px" }}>
            <h1 className="text-center mt-4 mb-1">Oh, Entschuldigung!</h1>
            <p className="text-center">Wir arbeiten gerade an dieser Seite.</p>
            <p className="text-center"><FontAwesomeIcon icon={faPhone}></FontAwesomeIcon> 01525 87 99 87 1</p>
            <a href="mailto:info@ziegenhagel.com" className="text-center"><p className="primary"><FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon> info@ziegenhagel.com</p></a>
            <Link className="btn btn-primary-outline text-center" to="/"><FontAwesomeIcon icon={faHome}></FontAwesomeIcon> Startseite</Link>
            <Link className="btn btn-primary-outline text-center" to="/services">Services <FontAwesomeIcon icon={faChevronRight}></FontAwesomeIcon></Link>
        </div>

        <br /><br /><br/>
    </Layout>
)

export default Page
